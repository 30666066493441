export default function CommandBlock(params) {
    return (
        <section className="command_block">
            <div className="career_menu">
                <a className="career_menu_link" href="#history_block">История</a>
                <a className="career_menu_link" href="#management_block">Руководство</a>
                <a className="career_menu_link" href="#mission_block">Миссия</a>
                <a className="career_menu_link" href="#presentation_block">Презентация</a>
            </div>
            <div className="container">
                <div className="numbers_company">
                    <div className="numbers_company_item">
                        <div className="numbers_company_counter">
                            <p className="numbers_company_counter_sign">{">"}</p>
                            <p className="numbers_company_item_title">20</p>
                        </div>
                        <p className="description">лет опыта экспертов</p>
                    </div>
                    <div className="numbers_company_item">
                        <p className="numbers_company_item_title">99%</p>
                        <p className="description">выигранных споров имеют в основе наши экспертные заключения;</p>
                    </div>
                    <div className="numbers_company_item">
                        <div className="numbers_company_counter">
                            <p className="numbers_company_counter_sign">{">"}</p>
                            <p className="numbers_company_item_title">15</p>
                        </div>
                        <p className="description">лет успешной и продуктивной работы</p>
                    </div>
                    <div className="numbers_company_item">
                        <div className="numbers_company_counter">
                            <p className="numbers_company_counter_sign">{">"}</p>
                            <p className="numbers_company_item_title">50</p>
                        </div>
                        <p className="description">допусков и аккредитаций</p>
                    </div>
                    <div className="numbers_company_item">
                        <div className="numbers_company_counter">
                            <p className="numbers_company_counter_sign">{">"}</p>
                            <p className="numbers_company_item_title">2500</p>
                        </div>
                        <p className="description">проектов, исследований, экспертиз ежегодно</p>
                    </div>
                </div>
                <div className="command_short">
                    <div className="command_short_text">
                        <p className="description">АНО НЭКЦ "СУДЭКС" проводит судебные экспертизы (исследования) по определению арбитражных судов и судов общей юрисдикции, по постановлениям органов следствия и дознания, обращениям адвокатских бюро и кабинетов, по заявкам юридических и частных лиц.</p>
                        <p className="description">Автономная некоммерческая организация «Национальный экспертно-криминалистический центр СУДЭКС» (АНО НЭКЦ "СУДЭКС") является негосударственным экспертным учреждением, действующим в рамках Федерального закона от 31.05.2001 № 73-ФЗ «О государственной судебной экспертной деятельности в Российской Федерации»,</p>
                        <p className="description">08 апреля 2022 года изменилось наименование автономной некоммерческой организации «Национальный экспертно-криминалистический центр». Новое наименование – автономная некоммерческая организация «Национальный экспертно-криминалистический центр «СУДЭКС», или сокращенно АНО НЭКЦ «СУДЭКС» (свидетельство о государственной регистрации некоммерческой организации № 7314050210 от 18 апреля 2022г.). Запись о некоммерческой организации внесена в Единый государственный реестр юридических лиц "14" января 2014 г. за основным государственным регистрационным номером 1147300000033.</p>
                    </div>
                    <div className="command_short_img">
                        <img src={process.env.PUBLIC_URL + "/img/command_block/picture.png"} alt="Команда" />
                    </div>
                </div>
            </div>
        </section>
    )
}