import { useState } from "react";

export default function GalleryBlock(props) {
    const LIMIT_IMAGE_TO_DRAW = 4;
    let [images, setImages] = useState([
        {
            "id": 0,
            "imageUrl": "/img/gallery_block/1.png",
            "isVisible": true,
            "isMainImage": false
        },
        {
            "id": 1,
            "imageUrl": "/img/gallery_block/2.png",
            "isVisible": true,
            "isMainImage": false
        },
        {
            "id": 2,
            "imageUrl": "/img/gallery_block/3.png",
            "isVisible": true,
            "isMainImage": false
        },
        {
            "id": 3,
            "imageUrl": "/img/gallery_block/4.png",
            "isVisible": true,
            "isMainImage": true
        },
        {
            "id": 4,
            "imageUrl": "/img/gallery_block/5.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 5,
            "imageUrl": "/img/gallery_block/6.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 6,
            "imageUrl": "/img/gallery_block/7.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 7,
            "imageUrl": "/img/gallery_block/8.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 8,
            "imageUrl": "/img/gallery_block/9.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 9,
            "imageUrl": "/img/gallery_block/10.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 10,
            "imageUrl": "/img/gallery_block/11.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 11,
            "imageUrl": "/img/gallery_block/12.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 12,
            "imageUrl": "/img/gallery_block/13.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 13,
            "imageUrl": "/img/gallery_block/14.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 14,
            "imageUrl": "/img/gallery_block/15.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 15,
            "imageUrl": "/img/gallery_block/16.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 16,
            "imageUrl": "/img/gallery_block/17.png",
            "isVisible": false,
            "isMainImage": false
        },
        {
            "id": 17,
            "imageUrl": "/img/gallery_block/18.png",
            "isVisible": false,
            "isMainImage": false
        },
    ]);
    let [isNextSlide, setIsNextSlide] = useState(false);

    function setFirstSlideToEnd() {
        let image = images[0];
        setImages([...images.slice(1, images.length), image]);
        setIsNextSlide(true);
    }

    function setEndSlideToFirst() {
        let image = images[images.length - 1];
        setImages([image, ...images.slice(0, images.length - 1)]);
        setIsNextSlide(false);
    }

    return (
        <section className="gallery_block" id="gallery_block">
            <div className="container">
                <div className="gallery_block_text">
                    <p className="title">Мы много работаем, но и весело отдыхаем</p>
                    <p className="description">Нашей компанией ежегодно реализуется более 2500 проектов,  исследований,  экспертиз,  оказывая услуги по всем регионам России</p>
                </div>
                <div className="gallery_slider" style={{transition: ".3s all"}}>
                    {images.map((item, idx) => {
                        if (idx < LIMIT_IMAGE_TO_DRAW) {
                            return (
                                <div 
                                    className={`gallery_slider_item ${isNextSlide ? "slider-career-next-slide" : "slider-career-previous-slide"} ${idx === 3 ? "active" : ""}`} 
                                    key={item.id}
                                >
                                    <img src={process.env.PUBLIC_URL + item.imageUrl} alt="Картинка" />
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <div className="gallery_switches">
                        <button className="main_button main_button_left" onClick={() => {setEndSlideToFirst()}}>
                            <img src={process.env.PUBLIC_URL + "/img/main_block/left.png"} alt="влево" />
                        </button>
                        <button className="main_button main_button_right" onClick={() => {setFirstSlideToEnd()}}>
                            <img src={process.env.PUBLIC_URL + "/img/main_block/right.png"} alt="вправо" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
