export  const dataServices = [
    {
        "groupingMenu": "Лаборатория строительно-технических и землеустроительных экспертиз",
        "menuId": 1,
        "photo": "/img/laboratory_block/picture1.png",
        "fon": "/img/laboratory_block/fon1.png",
        "subMenuItems": [
            {
                "subMenuTitle": "Строительно-техническая экспертиза",
                "subMenuId": 1,
                "description_service": `Строительно-техническая экспертиза проводится в случаях, когда при расследовании и судебном рассмотрении уголовных, гражданских, арбитражных дел и дел об административных правонарушениях требуется применение специальных знаний в области проектирования, строительства, эксплуатации, реконструкции (ремонта), демонтажа и утилизации зданий, строений и сооружений. Судебная строительно-техническая экспертиза относится к инженерно-техническому классу и основывается на знаниях в областях строительной механики, строительного материаловедения, технологии строительного производства, технологии изготовления строительных деталей и конструкций, организации строительного производства, проектирования и других.`,
                "main_directions": `<div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Обследование зданий и сооружений</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение стоимости качества выполненных работ</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение стоимости устранения дефектов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие строительных норм и правил</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Раздел домовладений</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза инженерных коммуникаций</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза капитальности (не капитальности) зданий</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза качества ремонта в квартире</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза многоквартирных жилых домов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза оконных проемов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза перепланировки/реконструкции</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза проектной документации</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза смет, сметной документации</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Тепловизионное обследование</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экологическая экспертиза</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Кадастровые работы</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Геодезия </p>
                                </div>`,
                "services_tasks": `-	определение стоимости жилого дома, дачи, летних домиков, хозяйственных построек и земельных участков;<br/>
                    -	определение технической возможности реального раздела жилого дома (части дома, квартиры), земельного участка, выделения части дома (квартиры) и разработка вариантов их раздела;<br/>
                    -	определение технической возможности реального раздела дач, садовых домиков, хозяйственно-бытовых построек и земельных участков, а также разработка вариантов их раздела;<br/>
                    -	определение соответствия выделенной части дома (квартиры) или земельного участка доле в общей собственности и выявление отклонений (в натуральном и стоимостном выражении) от идеальных долей;<br/>
                    <br/>
                    -	определение наличия деформаций основных несущих конструкций, а также их характера и величины;<br/>
                    -	определение факта превышения величины деформации допустимых нормами пределов;<br/>
                    -	определение факта аварийного состояния здания (сооружения);<br/>
                    <br/>
                    -	определение ущерба, возникшего в результате протекания воды через ограждающие конструкции помещений и места их соединения, и его возмещение;<br/>
                    -	определение нарушений строительных норм и правил;<br/>
                    -   определение физического износа сооружений и конструкций; методы проектирования, адаптированные для решения задач;<br/>
                    <br/>
                    -	определение методов диагностики скрытых дефектов, повреждений и деформаций строительных конструкций;<br/>
                    -	определение методов расчета прочности конструкций;<br/>
                    -	определение расчетных и расчетно-графических методов для определения величины и характера нагрузок на конструкции; площадей и объемов (участков земли, помещений, сооружений); инсоляции и освещенности.<br/>
                    <br/>`,
                "services_questions": `<div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли предоставленные помещения требованиям строительных норм и правил?</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли выделенные помещения по площади идеальным долям и каковы отклонения от них в случае несоответствия?</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Были ли допущены отступления от проекта при строительстве жилого дома (дачи) и в чем они заключаются?</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оказывает ли новый строительный объект негативное влияние на существующие здания, строения или сооружения и в чем это проявляется?</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Существует ли техническая возможность устранения указанного негативного влияния (если оно имеется) и какова стоимость необходимых для этого работ?</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Влияние выявленных отклонений от требований строительных норм и правил на прочность конструкций и безопасность эксплуатации дома (или другого строения), а также возможности, сроки и стоимость их устранения</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Является ли жилой дом (или другое здание, строение) аварийным?</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Наличие недостатков (дефектов, повреждений) дома, их количество и характер, наличие недостатков объемно-планировочных и конструктивных решений помещений и здания в целом, соответствие (или несоответствие) санитарным и противопожарным требованиям, требованиям к условиям комфортного проживания (достаточная освещенность, инсоляция, воздухообмен и т.д.), возможность устранения недостатков, стоимость необходимых для этого работ и стоимость жилого дома</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Признание недействительности обмена жилых помещений</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Наличие повреждений несущих конструкций и отделочных элементов</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Масштаб и характер этих повреждений</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Причины, условия и механизм возникновения и развития повреждений, наличие (или отсутствие) причинной связи между повреждениями конструктивных элементов дома, отделочных элементов и недостатками ограждающих конструкций (кровли, перекрытий), монтажом оборудования, отклонениями от правил монтажа и эксплуатации санитарно-технического оборудования, а также системы водостока дождевых и талых вод</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Объем и стоимость работ, необходимых для восстановления первоначального состояния помещений (до залива) посредством восстановительного ремонта</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Факты нарушений требований действующих правил безопасности труда, допущенных пострадавшим и другими лицами, ответственными за безопасные условия труда при организации и проведении строительных работ; наличие причинной связи между этими нарушениями (если они имели место) и наступившими последствиями</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Возможность предвидения и предотвращения произошедшего</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Техническая возможность и экономическая целесообразность восстановления поврежденного строительного объекта в случае, если несчастный случай был связан с аварией (например, частичным или полным разрушением здания, строения или сооружения)</p>
                                </div>`,                
            },
            {
                "subMenuTitle": "Пожарно-техническая экспертиза",
                "subMenuId": 2,
                "description_service": `Исследование объекта, поврежденного пожаром, проводится с целью установления причин и места возникновения пожара, выявления способствующих этому обстоятельств и действий (или бездействия) людей. На основании данных исследования составляется экспертное заключение для использования в судопроизводстве. СПТЭ является самостоятельным видом судебной экспертизы, относящимся к классу инженерно-технических экспертиз.`,
                "main_directions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление очага пожара (места первоначального возникновения горения).</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление путей распространения пожара.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление причины возникновения пожара.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление длительности протекания пожара.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление причастности к появлению теплового источника, вызвавшего возгорание, аварийных режимов в работе электрических сетей, аппаратов, установочных изделий.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление причастности к возгоранию устройств и агрегатов, использующих в своей работе (либо образующих при ней) открытое пламя или сильно разогретые газы, металлы и пр.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление организационно-технических причин, причастных к возникновению пожара.</p>
                                </div>`,
                "services_tasks": `-	Определение места первоначального возникновения пожара (очага возгорания);<br/>
                                    -	Определение причины возникновения пожара, включая выявление первоначально загоревшегося вещества или материала, источника тепловой энергии, вызвавшего возгорание, и условий, при которых воздействие данного теплового источника на вещество или материал стало возможным и привело к возгоранию.<br/>
                                    -	Определение путей распространения пожара с учетом взаимного расположения первоначально воспламенившегося предмета и окружающих его объектов; пожароопасных свойств веществ и материалов, из которых они изготовлены; особенностей термо- и газодинамических процессов горения и распространения газообразных продуктов горения и вылетающих из зоны горения раскаленных твердых частиц; воздействия на распространение пожара действий людей и механизмов, участвовавших в тушении, а также ряда других факторов, включая погодные условия.<br/>
                                    -	Определение продолжительности пожара.<br/>
                                    <br/>
                                    -	Определение причастности аварийных режимов работы электрических сетей, аппаратов и установочных изделий к появлению теплового источника, вызвавшего возгорание.<br/>
                                    -	Определение причастности к возгоранию устройств и агрегатов, использующих в своей работе или образующих при этом открытое пламя, сильно разогретые газы, металлы и другие подобные элементы.<br/>
                                    -	Определение организационно-технических причин возникновения пожара, включая действия или бездействие людей, находящихся в причинной связи с его возникновением, создание условий для быстрого или чрезмерного распространения огня, затруднение или невозможность тушения пожара и эвакуации людей из опасной зоны.<br/>
                                    -	Определение наличия или отсутствия в действиях людей отклонений от требований пожарной безопасности, связанных с организационно-технической причиной возникновения пожара.<br/>`,
                "services_questions": `<div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">«В каком месте произошло первоначальное возгорание (располагался очаг пожара)?»</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">«Какими путями огонь распространялся по объекту?»</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">«Какова была длительность пожара, учитывая термические повреждения объекта и пожароопасные свойства материалов, из которых он был сделан?»</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">«Какова была техническая причина возгорания?»</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">«Могло ли возгорание объекта быть вызвано тепловыми проявлениями аварийного режима работы электросети, установленной на объекте пожара? Если да, то в каком месте электросети и по какой причине возник этот аварийный режим?»</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">«Могло ли возгорание быть вызвано чрезмерным нагревом строительных конструкций здания деталями отопительного устройства (печи, обогревателя)? Соответствуют ли конструкция и размещение отопительного устройства в здании требованиям нормативных документов по пожарной безопасности? Если не соответствуют, существует ли прямая причинно-следственная связь между этими отклонениями и возникновением пожара?»</p>
                                </div>
                                <div class="description_item">
                                    <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">«Могло ли возгорание объекта быть вызвано внесением открытого огня извне? Каким образом это могло быть осуществлено? Имеются ли признаки использования горючих или легковоспламеняющихся жидкостей, либо других интенсивно горящих веществ или материалов при организации возгорания?»</p>
                                </div>`,                
            },
            {
                "subMenuTitle": "Экологическая экспертиза",
                "subMenuId": 3,
                "description_service": `Практическая деятельность, направленная на изучение негативного воздействия человека на конкретные объекты окружающей среды. Основной задачей является выявление фактов, подтверждающих наличие или отсутствие негативного воздействия на окружающую среду.`,
                "main_directions": ``,
                "services_tasks": `-	Определение и характеристика негативного антропогенного воздействия на окружающую среду в различные моменты времени и пространстве.<br/>
                                    -	Определение причинно-следственной связи между негативным антропогенным воздействием на окружающую среду и деятельностью производственных, коммунальных и иных предприятий и организаций.<br/>
                                    -	Определение и установление механизма негативного антропогенного воздействия;<br/>
                                    -	Определение масштабов, механизмов, обратимости (необратимости) и других последствий негативного антропогенного воздействия;<br/>
                                    <br/>
                                    -	Определение и выявление условий и обстоятельств, которые способствуют усилению или ослаблению негативного антропогенного воздействия.<br/>
                                    -	определение и установление источника антропогенного воздействия;<br/>
                                    -	Определение и установление факта причинения вреда окружающей среде в результате рассматриваемого события и его значительности с точки зрения экологии.<br/>
                                    <br/>
                                    -	Определение возможности восстановления объектов окружающей среды до исходного состояния и разработка списка необходимых рекультивационных и реабилитационных мероприятий. <br/>
                                    -	Определение возможности восстановления объектов окружающей среды до исходного состояния и разработка перечня необходимых рекультивационных и реабилитационных мероприятий;<br/>
                                    -   Определение стоимости восстановительных мероприятий для экономической оценки ущерба, нанесенного объектам окружающей среды.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определите местоположение источника негативного антропогенного воздействия.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Почему возникает негативное антропогенное воздействие?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие предельно допустимые концентрации вредных веществ в выбросах допускаются для данного вида производства?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие направления распространения опасных для здоровья людей и (или) вредных для окружающей среды веществ?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли исследуемый объект экологическим требованиям? Если нет, могли ли нарушения привести к негативному воздействию или распространению загрязняющих веществ, влияющих на окружающую среду?</p>
                                </div>`, 
            },
            {
                "subMenuTitle": "Землеустроительная экспертиза",
                "subMenuId": 4,
                "description_service": `Землеустройство включает в себя изучение состояния земель, планирование и организацию рационального использования земель, их охрану, определение местоположения и установление границ объектов землеустройства, а также организацию эффективного использования земельных участков гражданами и юридическими лицами для сельскохозяйственного производства.`,
                "main_directions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Кадастровые услуги</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Геодезия </p>
                                </div>`,
                "services_tasks": `-	определение состояния земель, включая почву, и причин их изменения, проводится с использованием почвенных, геоботанических и других методов исследования.<br/>
                                    -	Определение оценки качества земель, включая почву;<br/>
                                    -	Определение границ объекта (или объектов) землеустройства на местности.<br/>
                                    -	Определение причинно-следственной связи между состоянием земельного участка (участков) и расположенным на нем объектом (объектах) или с оказываемым на него определенным воздействием.<br/>
                                    <br/>
                                    -	Определение материалов по межеванию земельных участков проводится для проверки их соответствия специальным требованиям, включая выявление кадастровых ошибок.<br/>
                                    -	Определение соответствия установленных межевых знаков специальным требованиям, включая их правильное месторасположение и материалы по межеванию земельного участка.<br/>
                                    -	Определение рыночной и кадастровой стоимости земельных участков различного назначения, а также стоимости сервитута.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какая площадь имеет исследуемый земельный участок?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли фактическая площадь участка, который был определен в процессе экспертизы, заявленной площади в правоустанавливающих документах по указанному адресу?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Есть ли пересечение границ у земельных участков с соответствующими кадастровыми номерами?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">На земельном участке истца (расположенном по указанному адресу, имеющем указанную площадь и кадастровый номер), находится ли имущество ответчика?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли фактическое расположение имущества ответчика его заявленному местонахождению в документах?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие географические координаты угловых (поворотных) точек исследуемого земельного участка?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какая общая площадь пересекающихся частей земельных участков?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какая площадь земельного участка не застроена?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова площадь земельного участка, занятого строениями?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие координаты углов строения находятся на земельном участке?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Можно ли строить недвижимость на данном участке земли?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какая максимальная площадь строительства разрешена на этом участке земли?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли проведенные землеустроительные работы на исследуемом участке действующему законодательству?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Были ли соблюдены специальные правила застройки при проведении землеустроительных работ на исследуемом земельном участке?</p>
                                </div>`, 
            },
        ]
    },
    {
        "groupingMenu": "Лаборатория автотехнических и комплексных технических экспертиз",
        "menuId": 2,
        "photo": "/img/laboratory_block/picture2.png",
        "fon": "/img/laboratory_block/fon2.png",
        "subMenuItems": [
            {
                "subMenuTitle": "Автотехническая экспертиза",
                "subMenuId": 1,
                "description_service": `Автотехническая экспертиза - это специальное исследование
                            автотранспортных средств с целью оценки их текущего состояния и определения механизма
                            произошедших изменений. Обычно такая экспертиза проводится в отношении автомобилей,
                            побывавших в дорожно-транспортных происшествиях. Это исследование необходимо для определения
                            актуальной стоимости транспортного средства и ущерба, нанесенного его владельцу. Кроме того,
                            с помощью этого вида экспертизы можно выяснить подробности происшествия и определить
                            факторы, по которым суд впоследствии сможет установить виновника аварии.`,
                "main_directions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза обстоятельств ДТП</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза технического состояния транспортного
                                        средства</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Трасологическая экспертиза следов на ТС и месте ДТП
                                        (транспортно-трасологическая экспертиза)</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Автотавороведческая экспертиза</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза транспортных средств в целях определения
                                        стоимости восстановительного ремонта и оценки</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза технического состояния дороги. Дорожных
                                        условий на месте ДТП</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление технической возможности предотвращения
                                        ДТП</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определения взаимного расположения ТС в различные моменты
                                        ДТП</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление факта возникновения неисправности деталей ТС
                                        после ДТП</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение износа транспортного средства, его
                                        составляющих</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение рыночной стоимости транспортного средства и
                                        его отдельных элементов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Установление стоимости годных остатков транспортного
                                        средства</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение величины утраты товарной стоимости
                                        транспортного средства</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение размера страхового возмещения</p>
                                </div>`,
                "services_tasks": `-   Определение скорости движения и других параметров транспортного средства (ТС);<br/>
                                    -   Определение пути торможения, остановочного пути и времени остановки транспортного средства;<br/>
                                    -   Определение удаления транспортных средств, пешеходов и других объектов с места дорожно-транспортного происшествия в установленные органом (лицом), проводящим экспертизу, моменты.<br/>
                                    -   Определение технической возможности предотвращения ДТП в определенные моменты, установленное органом (лицом), назначившим экспертизу.<br/>
                                    <br/>
                                    -   Определение взаимного расположения транспортных средств в различные моменты дорожно-транспортного происшествия;<br/>
                                    -   Определение времени, необходимого для преодоления транспортным средством определенных участков пути.<br/>
                                    -   Определение момента возникновения опасности для движения, требующего принятия экстренных мер по предотвращению ДТП, таких как наезд на препятствие, столкновения транспортных средств, опрокидывания и других ситуаций, когда необходимы специальные знания для проведения расчетов, моделирования и экспериментов.<br/>
                                    <br/>
                                    -   Определение взаимного положения транспортных средств и препятствия в момент, когда водитель еще мог предотвратить происшествие.<br/>
                                    -   Определение того, как должен действовать водитель в случае ДТП с целью обеспечения безопасности дорожного движения.<br/>
                                    -   Определение того, какие конкретные действия водителя при управлении транспортным средством, начиная с момента возникновения опасности для движения, могли бы предотвратить дорожно-транспортное происшествие и какие именно требования Правил дорожного движения (ПДД) они регламентируют.<br/>
                                    <br/>
                                    -   Определение соответствия действий водителя транспортного средства требованиям Правил дорожного движения и другим нормативным документам, регламентирующим обеспечение безопасности на дороге.<br/>
                                    -   Определение технической возможности водителя в момент, указанный органом (лицом), назначившим экспертизу, совершить действия, регламентированные ПДД для предотвращения происшествия.<br/>
                                    -   Определение технической возможности водителя ТС предотвратить ДТП путем снижения скорости или объезда, устанавливается органом (лицом), назначившим экспертизу, в момент, когда водитель мог предвидеть возникновение опасности для движения или препятствия.<br/>
                                    <br/>
                                    -   Определение причинной связи между действиями (или бездействием) водителя при управлении транспортным средством и техническими последствиями (например, наезд, столкновение, опрокидывание и т. д.) на основе анализа технических данных и учета объективных закономерностей.<br/>
                                    -   Определение технической возможности предотвращения ДТП основывается не только на данных, предоставленных органом (лицом), проводившим экспертизу, но и на расчетных результатах, полученных экспертом. Эксперт учитывает несколько вариантов обстановки происшествия, вытекающих из материалов дела, и указывает на противоречия в исследованных вариантах в своем заключении.<br/>
                                    -   Определение причин и условий, связанных с организацией дорожного движения, которые способствуют совершению ДТП.<br/>
                                    <br/>
                                    -   Определение и установление технического состояния транспортных средств, их отдельных узлов, механизмов, систем и агрегатов;<br/>
                                    -   Определение причин и времени возникновения неисправности, возможность их своевременного выявления лицами, ответственными за техническое состояние транспортного средства, а также влияние этих неисправностей на возникновение и развитие дорожно-транспортных происшествий.<br/>
                                    -   Определение и установление причинно-следственных связей между неисправностью и ДТП, а также обстоятельств, способствующих возникновению неисправностей.<br/>
                                    <br/>
                                    -   Определение возможности технического предотвращения ДТП (наезда, столкновения, потери устойчивости и т. д.) при определенном техническом состоянии транспортных средств, их отдельных узлов, механизмов, систем, агрегатов в момент ДТП.<br/>
                                    -   Определение механизма дорожно-транспортного происшествия (ДТП);<br/>
                                    -   Определение механизма взаимодействия транспортных средств при столкновении.<br/>
                                    -   Определение механизма наезда на пешеходов (животных) и неподвижные препятствия.<br/>
                                    -   Определение угла между ТС и направлением удара в момент столкновения.<br/>
                                    <br/>
                                    -   Определение взаимного расположения транспортных средств относительно границ и осевой линии проезжей части;<br/>
                                    -   Определение и установление координат места столкновения транспортных средств или места наезда на пешеходов.<br/>
                                    -   Определение факта движения или неподвижности транспортного средства в момент первичного контактного взаимодействия при столкновении.<br/>
                                    -   Определение части ТС, по которой потерпевший получил повреждения.<br/>
                                    <br/>
                                    -   Определение места нахождения потерпевшего в салоне или кабине транспортного средства по характеру повреждений в момент столкновения.<br/>
                                    -   Определение частей ТС, которые контактировали между собой в момент первичного столкновения.<br/>
                                    -   Определение наличия, времени и причины повреждения шин ТС, гибких тормозных шлангов и резьбовых соединений.<br/>
                                    -   Определение факта возникновения неисправности деталей транспортного средства после дорожно-транспортного происшествия.<br/>
                                    <br/>
                                    -   Определение возможности получения механических повреждений на транспортных средствах при их контактном взаимодействии.<br/>
                                    -   Определение и установление обстоятельств, связанных с техническим состоянием транспортного средства, которые могли привести к возникновению дорожно-транспортного происшествия.<br/>
                                    -   Определение основных квалификационных характеристик дороги является важным и приоритетным. Особое внимание уделяется определению сцепных качеств автомобильных дорог в зависимости от типа и состояния покрытия, а также других признаков дифференциации.<br/>
                                    <br/>
                                    -   Определение и установление технического состояния структурных элементов и дорожных условий.<br/>
                                    -   Определение соответствия (несоответствия) фактических квалификационных характеристик и технического состояния участка автомобильной дороги, где произошло ДТП, элементов исследуемой дороги и дорожных условий требованиям нормативно-технической документации (НТД).<br/>
                                    -   Определение причин и времени образования дефектов на дороге, которые представляют угрозу для безопасности движения.<br/>
                                    -   Определение влияния ухудшения эксплуатационных характеристик автодороги и ее составляющих на причины дорожно-транспортных происшествий.<br/>
                                    <br/>
                                    -   Определение и установление уровня и качества информационного обеспечения водителя о дорожных условиях.<br/>
                                    -   Определение причинной связи между отклонениями квалификационных характеристик дороги и дорожных условий от требований НТД и происшедшим ДТП.<br/>
                                    -   Определение идентификации объекта экспертизы заключается в установлении фактических данных о марке, модели, модификации, комплектации, дате выпуска или периоде производства представленного на исследование транспортного средства.<br/>
                                    -   Определение и установление фактических данных о техническом состоянии транспортного средства в целом или его отдельных частях, узлах и агрегатах.<br/>
                                    <br/>
                                    -   Определение технологии и стоимости восстановительного ремонта транспортного средства после дорожно-транспортного происшествия или других повреждений.<br/>
                                    -   Определение наличия, характера и объема технических повреждений и их идентификация на предмет возникновения от конкретного события.<br/>
                                    -   Определение износа транспортного средства и его компонентов.<br/>
                                    -   Определение рыночной стоимости транспортного средства и его отдельных компонентов.<br/>
                                    -   Определение и установление стоимости оставшихся после использования транспортных средств.<br/>
                                    -   Определение утраты товарной стоимости транспортного средства.<br/>
                                    -   Определение суммы страхового возмещения.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Сколько будет стоить восстановительный ремонт транспортного средства?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова цена транспортного средства, учитывая его текущее техническое состояние, год выпуска, износ, особенности комплектации и наличие дополнительного оборудования?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какой размер утраты товарной стоимости применяется к транспортному средству, пострадавшему в результате ДТП?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Сколько стоит "годный остаток" транспортного средства?<br/>
                                    После ремонта автомобиля _____, выполненного _____ (СТО, дата ремонта и т.д.), есть ли какие-либо дефекты?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Сопоставить фактические повреждения транспортного средства, полученные в результате ДТП, с повреждениями, указанными в ______ (справке о ДТП, акте, заключении и т. д.);</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли повреждения автомобиля заявленным в ДТП повреждениям?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">В результате ДТП _____ (место/дата) могли ли возникнуть повреждения на автомобиле?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Водитель технически исправного автомобиля имел ли возможность избежать наезда на пешехода, принимая меры к торможению в опасный момент?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">В данной дорожной ситуации водитель автомобиля должен был руководствоваться какими пунктами ПДД для обеспечения безопасности дорожного движения?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определить скорость движения автомобиля на основе предоставленной видеозаписи.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определить причину дорожно-транспортного происшествия.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определить скорость движения автомобиля по зафиксированному следу торможения.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли техническое состояние транспортного средства техническим нормам и требованиям безопасности дорожного движения и эксплуатации транспорта?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Есть ли у транспортного средства технические недостатки (неисправности, дефекты)? Если да, то какие?</p>
                                </div>
                                
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Почему возникают недостатки?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Является ли обнаруженный недостаток производственным, конструктивным или эксплуатационным?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Является ли обнаруженный недостаток значительным или незначительным?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Как образуются существующие следы?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Есть ли следы на транспортном средстве №1 (преграде, одежде потерпевшего), которые могли быть оставлены частями транспортного средства №2?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Где произошло столкновение транспортных средств?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Под каким углом произошло столкновение транспортных средств?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Как расположены транспортные средства в момент ДТП?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определить путь движения транспортного средства до момента столкновения.</p>
                                </div>
                                
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Есть ли какие-либо заявленные неисправности или недостатки в двигателе транспортного средства в виде _____?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Если есть заявленные неисправности или недостатки, необходимо определить их причины: являются ли они заводскими или возникли в процессе эксплуатации?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определить качество ремонта двигателя транспортного средства, выполненного _____.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Есть ли какие-либо заявленные неисправности или недостатки в автоматической или механической коробке передач транспортного средства?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Если есть заявленные неисправности или недостатки, необходимо определить их причины: являются ли они заводскими или возникли в процессе эксплуатации?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определить качество ремонта коробки передач транспортного средства, выполненного _____.</p>
                                </div>
                                `,   
            },
            {
                "subMenuTitle": "Автотовароведческая экспертиза",
                "subMenuId": 2,
                "description_service": `Автотовароведческая экспертиза — это изучение экспертом различных транспортных средств (отдельных узлов) с целью определения их стоимости, оценки стоимости восстановительного ремонта, утраты товарной стоимости, оценки годных остатков, выявления причин неисправности и т.д.`,
                "main_directions": ``,
                "services_tasks": `-   Определение идентификации объекта экспертизы заключается в установлении фактических данных о марке, модели, модификации, комплектации, дате выпуска или периоде производства.<br/>
                                    -   Определение фактических данных о техническом состоянии транспортного средства в целом или его отдельных частей, узлов и агрегатов.<br/>
                                    -   Определение технологии и стоимости восстановительного ремонта транспортного средства после ДТП или других повреждений.<br/>
                                    -   Определение наличия, характера и объема технических повреждений и их идентификация, возникших в результате конкретного события.<br/>
                                    <br/>
                                    -   Определение степени износа транспортного средства и его компонентов.<br/>
                                    -   Определение рыночной стоимости транспортного средства и его отдельных компонентов.<br/>
                                    -   Определение стоимости годных остатков транспортного средства.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова стоимость восстановительного ремонта автомобиля (марка, модель) с государственным регистрационным знаком О 000 О 39, с учетом износа на дату дорожно-транспортного происшествия (далее – ДТП)?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова рыночная стоимость автомобиля такой же марки и модели, с аналогичной комплектацией и техническим состоянием, на момент ДТП в исправном состоянии?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каков размер утраты товарной стоимости автомобиля (марка, модель) с государственным регистрационным знаком О 000 О 39, причиненного в результате ДТП, произошедшего 00.00.0000 года?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова потеря стоимости автомобиля?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Стоит ли восстанавливать автомобиль с экономической точки зрения?</p>
                                </div>`, 
            },
            {
                "subMenuTitle": "Автооценочная экспертиза",
                "subMenuId": 3,
                "description_service": `Автооценочная экспертиза исчисляет текущую стоимость автомобиля в том виде, в котором он находится.`,
                "main_directions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Для представления оценочной стоимости автомобиля в суде.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">При разделе имущества в случае развода.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">При взносе автомобиля в уставной капитал предприятия (компании, организации).</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">В случае переоценки основных средств организации (с целью оптимизации налоговых выплат).</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">При получении кредитных средств – если автомобиль входит в залоговое обеспечение.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение стоимости пригодных остатков автомобиля. Производится для выполнения операций списания, утилизации или ликвидации.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">При утрате товарной стоимости машины (оценивается объем утраты).</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">При продаже автомобиля юридическим лицом.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">При оформлении или получении наследства – оценка предъявляется нотариусу.</p>
                                </div>`,
                "services_tasks": `-   определении текущей стоимости автомобиля на основе его состояния и среднерыночных показателей. Это исследование проводится для оценки ущерба, нанесенного автомобилю.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова текущая рыночная стоимость автомобиля?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Сколько стоит использованный автомобиль в хорошем состоянии?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каков размер материального ущерба, причиненного владельцу автомобиля в результате дорожно-транспортного происшествия?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова величина потери стоимости автомобиля?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Сколько стоят отдельные детали автомобиля?</p>
                                </div>`, 
            },
            {
                "subMenuTitle": "Экспертиза обстоятельств ДТП",
                "subMenuId": 4,
                "description_service": `Экспертиза обстоятельств ДТП - это один из видов автотехнической экспертизы, который занимается реконструкцией дорожно-транспортного происшествия с учетом всех сопутствующих факторов и изучением окружающей обстановки. Этот вид исследования применяется в случаях, когда необходимо точно восстановить произошедшее событие, особенно если нет надежных свидетельских показаний или они противоречивы и недостаточно информативны.`,
                "main_directions": ``,
                "services_tasks": `-   Определение фактических данных о обстоятельствах дорожно-транспортных происшествий, связанных с механизмом ДТП, дорожной обстановкой и действиями участников ДТП.<br/>
                                   -   Определение и установление причин и условий, которые способствовали возникновению дорожно-транспортного происшествия (ДТП), анализ технического состояния транспортных средств и дороги, а также деталей, узлов, агрегатов и систем транспортных средств, а также психофизиологических качеств участников ДТП.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие причины лежат в основе данного дорожно-транспортного происшествия?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каков точный механизм этого дорожно-транспортного происшествия?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какая полоса была занята автомобилем непосредственно перед столкновением?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какое состояние было у дорожного полотна на участке трассы, где произошло дорожно-транспортное происшествие?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каково расстояние торможения?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каково расстояние полного тормозного пути?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Сколько времени заняло преодоление указанного расстояния данным автомобилем?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каковы были дорожно-транспортные условия в момент происшествия? Могли ли они стать причиной ДТП?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли свидетельские показания (показания потерпевших, обвиняемых) результатам технических расчетов?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Был ли автомобиль в состоянии заноса? Какие внешние факторы могли на это повлиять?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Могут ли действия водителя и принятые им решения быть причиной дорожно-транспортного происшествия?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Есть ли в автомобиле, который мы исследуем, какие-либо неисправности (поломки, заводской брак)?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Мог ли водитель (владелец) автомобиля заметить поломку (неисправность, заводской брак) заранее?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Имеет ли исправную тормозную систему исследуемое автотранспортное средство?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Были ли выявленные неисправности обнаружены во время дорожно-транспортного происшествия или до него?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Влияло ли состояние дорожного покрытия на опрокидывание исследуемого автомобиля?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какой из водителей автотранспортных средств имел право на приоритет на нерегулируемом перекрестке, где произошло дорожно-транспортное происшествие?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие конкретные положения Правил дорожного движения были нарушены водителем (водителями)?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Кто еще был рядом с участниками ДТП? Мог ли водитель избежать столкновения, совершив маневр?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Является ли остановочный след непрерывным? Имеет ли он пробелы? Какие факторы могут объяснить прерывистость остановочного следа данного автомобиля?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие причины могут объяснить различия между тормозными следами, оставленными левой и правой парами колес автотранспортного средства?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какая была скорость автомобиля перед столкновением?</p>
                                </div>
                                 <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Сколько времени прошло с начала торможения до столкновения?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Были ли погодные и дорожные условия такими, чтобы водитель мог своевременно заметить пешехода, вышедшего на проезжую часть?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Была ли у водителя транспортного средства возможность избежать наезда путем своевременного торможения на необходимом для этого расстоянии?</p>
                                </div>`, 
            },
        ]
    },
    {
        "groupingMenu": "Лаборатория товароведческих и финансово-экономических экспертиз",
        "menuId": 3,
        "photo": "/img/laboratory_block/picture3.png",
        "fon": "/img/laboratory_block/fon3.png",
        "subMenuItems": [
            {
                "subMenuTitle": "Товароведческая экспертиза",
                "subMenuId": 1,
                "description_service": `Рассмотрение гражданских исков по разделу имущества, исключению имущества из описи, возмещению ущерба при повреждении или порче имущества, а также реализации некачественных изделий. Суть проводимого судебно-товароведческого исследования заключается в изучении товарных характеристик объекта с использованием специальных знаний для установления его классификационной принадлежности, фактического состояния и причин (условий, обстоятельств) его изменения.`,
                "main_directions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Исследование промышленных (непродовольственных) товаров, в том числе с целью проведение их оценки</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза по 44- ФЗ – Приемка по госконтракту</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза обуви, швейных и трикотажных изделий, натуральной кожи и меха</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза мебели</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза кухонной бытовой техники</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Исследование информационных компьютерных средств</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение стоимости при разделе имущества</p>
                                </div>`,
                "services_tasks": `-	определение классификационной принадлежности объекта, его назначения и области применения, включая метод реконструкции объекта по его части;<br/>
                                    -	определение принадлежности отдельных единиц или групп объектов к одной классификационной категории.<br/>
                                    -	определение наличия дефектов и их влияния на качество<br/>
                                    -	определение степени снижения качества с учетом выявленных дефектов;<br/>
                                    <br/>
                                    -	определение соответствия или несоответствия свойств объекта базовым (стандартным, эталонным) требованиям;<br/>
                                    -	определение пригодности объекта для использования по назначению;<br/>
                                    -	определение соответствия или несоответствия фактических свойств объектов данным, указанным в маркировке или сопроводительных документах.<br/>
                                    <br/>
                                    -	определение соответствия или несоответствия нормативным требованиям: упаковки, маркировки, условий транспортировки, режима хранения, порядка приемки, методов испытаний, условий эксплуатации, а также установление причинно-следственной связи между несоблюдением правил осуществления процессов, операций и наступившим событием.<br/>
                                    -	определение типа негативного воздействия;<br/>
                                    -	определение возможных изменений свойств объекта при определенных условиях;<br/>
                                    <br/>
                                    -	определение причины изменения свойств объекта;<br/>
                                    -	определение розничной (рыночной) цены объекта судебно-товароведческой экспертизы и его стоимости с учетом фактического состояния;<br/>
                                    -	определение фактических данных, устанавливаемых в результате исследования объектов товарного происхождения, их отношений (связей) с явлениями окружающей действительности (различными воздействиями) и функционально связанных с ними процессов (упаковкой, транспортировкой, хранением, эксплуатацией);<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие наличия и качества фактически вложенного сырья документальным данным об изготовлении продукции, а также выявление имеющихся различий, если таковые имеются;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие фактических характеристик продукции (сырье, состав, материалы, технические параметры, размерные данные и др.) маркировочным обозначениям, указанным на ярлыке, этикетке, упаковке, изделии, а также выявление различий, если таковые имеются;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие обозначенных на маркировке символов характеристик продукции нормативным требованиям, установленным для исследуемого вида продукции, а также выявление различий, если таковые имеются;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие характеристик продукции, указанных в сопроводительных документах, фактическим характеристикам, а также выявление различий, если таковые имеются;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие фактического качества продукции требованиям стандартов, установленным для исследуемого вида, а также выявление отклонений, если таковые имеются, и определение возможности (допустимости) ее реализации, переработки или утилизации;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие упаковки товара требованиям стандартов, а также анализ влияния несоответствующей упаковки на снижение качества товара, если таковое имеется;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие условий транспортировки товара установленным нормативной документацией, а также анализ возможности ухудшения качества товара вследствие неправильной транспортировки, если таковая имела место;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие условий хранения товара нормативным требованиям, а также анализ влияния конкретных условий хранения на снижение его качества, если таковое имело место;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие приемки и испытаний продукции правилам, предусмотренным стандартами, а также выявление нарушений, если таковые имеются;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие характеристик изделий аналогичным показателям образцов, а также выявление различий, если таковые имеются;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствие качества представленных для исследования изделий установленным требованиям зависит от наличия и характера дефектов. Если дефекты присутствуют, то как именно они влияют на качество</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли представленные для исследования изделия стандартам качества при наличии дефектов? Если да, то как именно дефекты влияют на качество?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли выявленные дефекты допустимым нормам? Если да, то на сколько процентов снижается качество изделий?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли изделия, собранные в комплект или набор, установленным правилам по количеству, размеру, расцветке, оттенку и другим показателям? Если нет, то возможна ли их дальнейшая реализация?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствуют ли расчёты естественной убыли при конкретных условиях транспортировки и хранения существующим нормам и правилам?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли стоимость изделий их износу на момент повреждения (пожар, протечка и т. д.)?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли стоимость («остаточная стоимость») изделий, повреждённых в результате протечки, их пригодности для использования по назначению?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли степень снижения качества и стоимости изделий в результате повреждения уровню «ущерба»?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Вопрос о способе изготовления изделий (кустарный или фабрично-заводской) не относится к компетенции эксперта-товароведа.</p>
                                </div>`, 
            },
            {
                "subMenuTitle": "Финансово-экономическая и бухгалтерская экспертиза",
                "subMenuId": 2,
                "description_service": `Изучение финансовых операций и финансовых показателей деятельности предприятия основано на анализе документов бухгалтерского учета и отчетности, а также на рассмотрении фактических данных, отражающих образование, распределение и использование доходов, денежных средств (фондов), а также отклонения в этих процессах, которые могли повлиять на показатели хозяйственной деятельности или привести к преступлениям, связанным с нарушением финансовой дисциплины. Также исследование включает в себя данные хозяйственных операций, отраженные в документах бухгалтерского учета и отчетности, содержащие информацию о состоянии, движении материальных ценностей и денежных средств, их источниках, а также может рассматривать другие экономически значимые документы, такие как реестры налогового учета и счета-фактуры, выписки из счетов хозяйствующего субъекта, ценные бумаги (векселя, облигации и т. д.).`,
                "main_directions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определения наличия или отсутствия материальных ценностей и денежных средств</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Определение обстоятельств, имеющих отношение к недостаткам и излишкам</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Исследование записей бухгалтерского учета с целью установления наличия или отсутствия в них искаженных данных</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Исследование показателей финансового состояния и финансово-экономической деятельности хозяйствующего субъекта</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Аудит: комплексный обязательный, налоговый. Финансовый, отчетности МСФО , по заданию</p>
                                </div>`,
                "services_tasks": `-	Определение финансового состояния хозяйствующего субъекта предполагает анализ его платежеспособности, ликвидности, финансовой устойчивости и рентабельности.<br/>
                                    -	Определение соответствия или несоответствия направления расходования государственных финансов их предполагаемому использованию.<br/>
                                    -	Определение полноты и своевременности исполнения кредитных и иных обязательств хозяйствующего субъекта.<br/>
                                    -	определение кредитоспособности хозяйствующего субъекта;<br/>
                                    <br/>
                                    -	Определение полноты и своевременности исполнения обязательств по перечислению сумм налогов и других обязательных платежей.<br/>
                                    -	Определение характеристики состояния расчетов между контрагентами;<br/>
                                    -	Определение  правильности, своевременности и полноты исполнения работодателем своих обязательств по расчетам с персоналом и лицами, привлеченными для выполнения работ по договорам гражданско-правового характера;<br/>
                                    <br/>
                                    -	Определения  наличия финансовой возможности выполнения обязательств хозяйствующим субъектом;<br/>
                                    -	определение правильности и полноты расчетов хозяйствующего субъекта с учредителями, участниками, акционерами и другими лицами;<br/>
                                    -	определение полноты и правильности формирования взносов учредителя/участника, расчетов сумм дивидендов, величины чистых активов общества, определение стоимости доли имущества, причитающейся участнику общества при выходе из него;<br/>
                                    -	определение рыночной стоимости долевых инструментов юридических лиц.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Как изменялось финансовое состояние АО "Л" с 01.01.20ХХ года до даты подачи кредитором АО "К" иска о взыскании долга? Было ли АО "Л" способно выплачивать долги в этот период?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Как сделка по передаче основных средств по договору (реквизиты) повлияла на способность АО "Ж" отвечать по своим обязательствам?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли направление расходования денежных средств, выделенных из областного бюджета в рамках программы "Поддержка", на приобретение медицинского оборудования районной больницей Н-ска по договорам (реквизиты), условиям (наименование оборудования, производитель, количество, сроки поставки и др.), определенным в Приложениях 1 – 3 указанной программы?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">На момент получения ООО "ТД "И" денежных средств в размере 200 млн. руб. по кредитному договору от КБ "П" (ПАО), было ли оно кредитоспособным? Соответствует ли использование кредитных средств, полученных ООО "ТД "И" по договору, их назначению, установленному КБ "П" (ПАО)? Были ли соблюдены принципы кредитования и внутренние регламенты КБ "П" (ПАО) при предоставлении кредита ООО "ТД "И"?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Были ли отражены в выручке от реализации ООО «М» за период с 01.01.20хх по 31.03.хх поступления денежных средств от продажи товаров по договору (указать реквизиты)? Если нет, то как это отразилось на суммах НДС, подлежащем уплате за 1 квартал 20хх?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова величина кредиторской задолженности АО «Б» перед ООО «Р» по состоянию на 30.06.20хх за товар, поставленный по договору, с учетом сумм авансовых платежей, перечисленных по платежным документам?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Правильно ли были рассчитаны суммы денежных средств, которые должны были быть выплачены сотруднице П. за выполнение своих обязанностей по трудовому договору в течение 20хх года?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Была ли у ООО "Х" финансовая возможность выплачивать заработную плату механику Я. и другим сотрудникам общества в период с 01.02 по 30.07.20хх?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова реальная стоимость доли имущества, которая принадлежит А. при выходе из состава участников ООО "Т" на основе бухгалтерской отчетности общества на 30.09.20хх?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Согласно решению общего собрания акционеров, были рассчитаны суммы дивидендов, которые должны быть выплачены акционерам О. и П. АО "Ф" за 20хх год?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">На 30.07.20хх, какова рыночная стоимость акций АО "Ц"?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какова текущая рыночная стоимость доли участника С. в уставном капитале ООО "Г" на 30.07.20хх?</p>
                                </div>`, 
            },
            {
                "subMenuTitle": "Оценочная экспертиза",
                "subMenuId": 3,
                "description_service": `Оценочная экспертиза - это специфический вид экспертной работы, направленный на определение стоимости различных объектов в денежном выражении.`,
                "main_directions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка бизнеса</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка предприятия</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка недвижимости</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка незавершенного строительства</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка земельных участко</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка ущерба от пожара (залива)</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка ценных бумаг</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка транспорта</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Оценка объектов интеллектуальной собственности</p>
                                </div>`,
                "services_tasks": `- определение состояния земель, включая почву, и причин их изменения, проводится с помощью почвенных, геоботанических и других исследований.<br/>
                                    - определение качества земель, включая почву<br/>
                                    - определение границ объекта (объектов) землеустройства на местности;<br/>
                                    - Определение причинно-следственной связи между состоянием земельного участка (участков) и расположенным на нем объектом (объектах) или с оказываемым на него определенным воздействием.<br/>
                                    <br/>
                                    - Определение материалов для межевания земельных участков с целью проверки их соответствия специальным требованиям, включая выявление кадастровых ошибок.<br/>
                                    - определение соответствия установленных межевых знаков специальным требованиям, включая их правильное месторасположение и материалы по межеванию земельного участка.<br/>
                                    - Определение стоимости земельных участков различного назначения на рынке и в кадастре, а также стоимости сервитута.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Где на местности находятся границы землеустройства?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли межевание специальным требованиям? Наблюдаются ли кадастровые ошибки?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каково текущее состояние земельного участка, включая состояние почвы?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каково качество земель, включая почву, на данном участке?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие причинно-следственные связи существуют между состоянием земельного участка и находящимся на нем объектом?</p>
                                </div>`, 
            },
        ]
    },
    {
        "groupingMenu": "Лаборатория криминалистических и комплексных экспертиз",
        "menuId": 4,
        "photo": "/img/laboratory_block/picture.png",
        "fon": "/img/laboratory_block/fon4.png",
        "subMenuItems": [
            {
                "subMenuTitle": "Криминалистическая экспертиза",
                "subMenuId": 1,
                "description_service": `Криминалистическая экспертиза — вид судебной экспертизы, который исследует следы — улики, оставляемые людьми и вещами. `,
                "main_directions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза почерка и подписей</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза рукописной буквенной и /или цифровой записи</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза давности рукописного текста</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза давности печати</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза оттисков печатей и штампов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Комплексная технико-криминалистическая и почерковедческая экспертиза</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза давности создания документов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза рукописных текстов </p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза печатных текстов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза последовательности выполнения реквизитов документа</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза документов с изменённым первоначальным содержанием</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза по определению монтажа документов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Экспертиза по восстановлению содержания документов</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Техническая экспертиза документов</p>
                                </div>`,
                "services_tasks": `-   Идентификация объекта.<br/>
                                    -   Определение текущего состояния объекта.<br/>
                                    -   Установление возможности проведения определённых действий с объектом.<br/>
                                    -   Обнаружение скрытых следов зашифрованного содержания.<br/>
                                    -   Определение принадлежности объектов к определенной группе или их назначения.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Как был создан данный документ?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какие методы использованы для оформления реквизитов документа?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Как была выполнена копия документа?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Были ли внесены изменения в содержание документа? Если да, то каким образом и какие именно реквизиты были изменены?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какое изначальное содержание измененного документа или реквизитов?;</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Каков порядок выполнения реквизитов штрихов в документе? Что выполняется первым - печатный текст, подпись или оттиск печати?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Существуют ли признаки использования технических средств для создания подписи в документе?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">На документах нанесены оттиски одной или разных печатей?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">На каком печатном оборудовании были напечатаны реквизиты документа?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Какой метод печати или воспроизведения реквизитов был использован при создании документа и его реквизитов?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Были ли реквизиты воспроизведены одним или разными способами в разных документах?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Где находился документ во время его создания: на мягкой, твердой, гладкой или неровной поверхности?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Необходимо определить, соответствует ли дата составления исследуемого документа дате, указанной на нем, по любым доступным реквизитам.</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Если дата на документе отличается от даты его составления, необходимо определить период, в который был составлен данный документ, и (или) установить период времени, когда реквизиты указанного документа не могли быть выполнены.</p>
                                </div>`, 
            },
            {
                "subMenuTitle": "Почерковедческая экспертиза",
                "subMenuId": 2,
                "description_service": `Почерковедческая экспертиза относится к традиционным криминалистическим исследованиям. Она широко применяется в следственной и судебной практике, так как может быть назначена по различным категориям уголовных, административных и гражданских дел, включая арбитражные. Это происходит в случаях, когда при расследовании и судебном разбирательстве необходимо установить факты, связанные с исследованием рукописей.<br /><br />
                Для проведения почерковедческой экспертизы  (подписи) эксперту необходимо иметь оригинал спорного текста (записи или подписи). В противном случае эксперт по результатам исследования может сделать лишь вероятностный вывод.`,
                "main_directions": ``,
                "services_tasks": `-	определение индивидуальной идентификации: установление наличия или отсутствия совпадения конкретного исполнителя рукописи; определение, выполнены ли несколько различных текстов, подписей, отдельных фрагментов текста, текста и краткой записи (например, дописки), текста и подписи одним и тем же лицом или разными лицами;<br/>
                                    -	определение групповой принадлежности лица, выполнившего рукопись (по степени выработанности почерка, совершенству системы движений и другим общим признакам почерка);<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Является ли С. автором текста расписки от 27 ноября 1979 г. на сумму 550 р.?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Кем именно, П., С. или другим лицом, написан текст письма, начинающийся словами «Уважаемый товарищ директор...» и заканчивающийся словами «...чего-нибудь не случилось?»?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Кем выполнена подпись в завещании от 12.02.92 г. — самим И. или другим лицом?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Кем выполнена заверительная запись «Иванов Олег Михайлович» в завещании от 12.02.92 г. — самим И. или другим лицом?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Одним или разными лицами написаны два письма, начинающиеся и заканчивающиеся словами: «Уважаемый товарищ директор...» и «...чего-нибудь, не вышло»; «Довожу до Вашего сведения...» и «...все сообщенное мной верно»?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Одним или разными лицами написаны основной текст расписки от 27 ноября 1979 г. на сумму 550 р. и заключительная запись «Указанную сумму обязуюсь вернуть полностью к 1 июля 1980 г.»?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Одним или разными лицами написаны основной текст расходного ордера и добавленные слова и цифры?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Одним ли лицом выполнены подписи от имени вымышленного лица Смирнова Л.В., расположенные в графах 8–10 ведомости на получение зарплаты за май 1993 г.?</p>
                                </div>`, 
            },
            {
                "subMenuTitle": "Экспертиза давности документов",
                "subMenuId": 3,
                "description_service": `Экспертиза давности документа - это криминалистическое исследование, которое направлено на определение даты создания официальной бумаги и ее частей. Такая экспертиза позволяет подтвердить или опровергнуть подлинность документа в суде с помощью заключения эксперта, которое вы получите после завершения исследования.<br /><br />
                СЛУЧАИ, КОГДА НЕВОЗМОЖНО ПРОВЕСТИ ЭКСПЕРТИЗУ ДАВНОСТИ ДОКУМЕНТОВ:<br /><br />
                - если недостаточно образцов для проведения сравнительного анализа;<br />
                - если документ находится в плохом состоянии из-за неправильного хранения или умышленного повреждения, либо если утеряны первоначальные характеристики. Загрязненные, выцветшие, ломкие или плесневелые документы не могут быть подвергнуты экспертизе для определения давности создания.`,
                "main_directions": ``,
                "services_tasks":  `-   Определение факта и метода агрессивного воздействия на документ, включая его намеренное старение.<br/>
                                    -   Определение времени, затраченного на выполнение рукописных реквизитов (текста, записей, подписей);<br/>
                                    -   Определение времени, когда были нанесены на документ оттиски печатей, штампов и факсимиле.<br/>
                                    -   Определение времени, затраченного на изготовление печатных текстов с использованием различных методов.<br/>`,
                "services_questions": `<div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли дата составления Договора поставки без номера от _____ 20__ года указанной в нем, а именно «__» __ 20__ года? Если нет, то когда был составлен Договор поставки без номера от ____ 20__ года?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли давность выполнения подписи от имени С.А. Иванова в Договоре займа от ___ 20__ года, дате, указанной в нем, а именно ___ 20___ года? Если не соответствует, то когда была выполнена подпись от имени С.А. Иванова?</p>
                                </div>
                                <div class="description_item">
                                     <img src="/img/laboratory_block/point.png" alt="Точка"/>
                                    <p class="description">Соответствует ли дата оттиска печати ООО "___" в Договоре поставки от ___ 20__ года указанной дате, а именно ___ 20__ года? Если нет, то когда был нанесен оттиск печати ООО "___" в Договор поставки от ___ 20__ года?</p>
                                </div>`, 
            },
        ]
    },
];

export default dataServices;